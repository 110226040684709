import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const ItemContainer = styled.div`
  max-width: 510px;
  margin-bottom: 50px;
  padding: 15px;

  h1 {
    color: #152540;
    font-size: 24px;
    line-height: 38px;
  }
  p,
  span {
    color: #8494b2;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0px 1px 4px rgba(0, 8, 32, 0.1),
      0px 32px 64px rgba(0, 8, 32, 0.12);
    border-radius: 10px;
  }
`

const StyledImage = styled.img`
  max-width: 500px;
  border-radius: 10px;
  min-height: 260px !important;

  @media (min-width: 1024px) {
    max-height: auto;
  }
`
const Category = styled.span`
  text-transform: capitalize;
  color: #5a55ab !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 26px !important;
`

const BlogItemLarge = ({
  url,
  title,
  excerpt,
  author,
  category,
  readingTime,
  image,
}) => (
  <ItemContainer>
    <article itemType="http://schema.org/Article">
      <Link to={url}>
        <GatsbyImage image={image} alt="" style={{ borderRadius: `10px` }} />
        <div>
          <Category>{category}</Category>
          <span>{readingTime}</span>
        </div>
        <h1>{title}</h1>
        <section itemProp="description">
          <p>{excerpt}</p>
        </section>
        <span>{author}</span>
      </Link>
    </article>
  </ItemContainer>
)

export default BlogItemLarge
