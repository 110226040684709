/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const ItemContainer = styled.div`
  max-width: 475px;
  min-height: 97px;
  padding: 15px;
  display: flex;
  flex-wrap: nowrap;
  div {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    min-height: 97px;
  }
  article {
    min-height: 97px;
  }
  h1 {
    color: #152540;
    font-size: 16px;
    line-height: 20px;
    margin-top: 0;
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0px 1px 4px rgba(0, 8, 32, 0.1),
      0px 32px 64px rgba(0, 8, 32, 0.12);
    border-radius: 10px;
  }
`
const Category = styled.span`
  text-transform: capitalize;
  color: #5a55ab;
  font-weight: 600;
  font-size: 12px;
  line-height: 26px;
`
const BlogItemSmall = ({ url, title, category, readingTime, image }) => (
  <ItemContainer>
    <article itemType="http://schema.org/Article">
      <Link to={url} style={{ display: `flex` }}>
        <GatsbyImage
          image={image}
          alt=""
          style={{
            maxWidth: `151px`,
            maxHeight: `115px`,
            borderRadius: `10px`,
          }}
        />
        <div>
          <section>
            <Category>{category}</Category>
            {/* <span>{readingTime}</span> */}
            <h1>{title}</h1>
          </section>
        </div>
      </Link>
    </article>
  </ItemContainer>
)

export default BlogItemSmall
