/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import React from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import slugify from 'slugify'
import styled from 'styled-components'
import BlogItemLarge from '../components/blog/BlogItemLarge'
import BlogItemMedium from '../components/blog/BlogItemMedium'
import BlogItemSmall from '../components/blog/BlogItemSmall'
import SEO from '../components/Seo'

const LatestPosts = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 1140px;
  padding: 0 15px;
  margin-top: 50px;

  @media (min-width: 1024px) {
    justify-content: space-between;
    border-bottom: 1px solid #d9e1ee;
    padding: 0 15px 60px;
  }
`
const LatestPostsSmall = styled.div`
  display: none;
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`

const LatestPostsLarge = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    display: none;
  }
`
const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1140px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  @media (min-width: 1024px) {
    align-items: flex-start;
    margin-top: 60px;
  }
`
const CategoryPosts = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  padding: 0 15px 120px;
  border-bottom: 1px solid #d9e1ee;
`
const PostsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: baseline;
  }
`
const IframeContainer = styled.span`
  padding-bottom: 56.25%;
  position: relative;
  display: block;
  width: 100%;

  > iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const BlogIndex = ({ pageContext: { data } }) => {
  const posts = data
  const latestPosts = posts.slice(0, 5)
  const otherPosts = posts.slice(5)
  const lateExcerpt = renderRichText(latestPosts[0].excerpt).slice(0, 90)
  return (
    <>
      <SEO
        title="Blog - Innential"
        description="Innential helps your teams perform and grow through hyper personalised learning."
      />
      <LatestPosts>
        <div>
          <BlogItemLarge
            url={`/blog/${latestPosts[0].slug}`}
            key={latestPosts[0].id}
            title={latestPosts[0].title}
            excerpt={lateExcerpt}
            image={latestPosts[0].heroImage?.gatsbyImageData}
            category={latestPosts[0].category.category}
          />
        </div>
        <LatestPostsSmall>
          {latestPosts.map((post, index) => {
            if (index > 0) {
              return (
                <BlogItemSmall
                  url={`/blog/${post.slug}`}
                  key={post.id}
                  title={post.title}
                  image={post.heroImage?.gatsbyImageData}
                  // author={post.author}
                  category={post?.category?.category}
                />
              )
            }
          })}
        </LatestPostsSmall>
        <LatestPostsLarge>
          {latestPosts.map((post, index) => {
            const excerpt = renderRichText(post.excerpt).slice(0, 90)
            if (index > 0) {
              return (
                <BlogItemMedium
                  url={`/blog/${post.slug}`}
                  key={post.id}
                  title={post.title}
                  excerpt={excerpt}
                  image={post.heroImage?.gatsbyImageData}
                  // author={post.author}
                  category={post.category.category}
                />
              )
            }
          })}
        </LatestPostsLarge>
      </LatestPosts>

      <CategoryContainer>
        <CategoryPosts>
          <PostsList>
            {otherPosts.map((post) => {
              const excerpt = renderRichText(post.excerpt).slice(0, 90)
              return (
                // <pre>{JSON.stringify(post, null, 2)}</pre>
                <BlogItemMedium
                  url={`/blog/${post.slug}`}
                  key={post.id}
                  title={post.title}
                  excerpt={excerpt}
                  image={post.heroImage?.gatsbyImageData}
                  // author={post.author}
                  category={post.category.category}
                />
              )
            })}
          </PostsList>
        </CategoryPosts>
      </CategoryContainer>
    </>
  )
}
export default BlogIndex
